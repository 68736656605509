import React, {StrictMode, Suspense} from 'react';
import {CenteredSpinner} from '@/components/animation';
import AppContext from '@/AppContext';
import App from '@/App';
import {useMeResource} from '@/services/api';
import {ConfigurationResponse} from '@shared/responseModels';
import {LoadingProvider} from '@/services';
import {GlobalOverlay} from '@/components/indicators';

interface Props {
    configuration: ConfigurationResponse;
}

function AppContainer({configuration}: Props) {
    const meResource = useMeResource();
    const me = meResource.get();

    return (
        <div>
            <Suspense fallback={<CenteredSpinner/>}>
                <AppContext configuration={configuration}>
                    <LoadingProvider>
                        <GlobalOverlay/>
                        <App me={me}/>
                    </LoadingProvider>
                </AppContext>
            </Suspense>
        </div>
    );
}

function makeAppContainer(configuration: ConfigurationResponse) {
    return (
        <StrictMode>
            <AppContainer configuration={configuration}/>
        </StrictMode>
    );
}

export {
    makeAppContainer,
};

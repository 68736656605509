import {useMemo} from 'react';
import axios, {AxiosResponse} from 'axios';
import {
    BrandSettingsResponse,
    CustomCampaignListResponse,
    LocationDetailsResponse,
    LocationListResponse,
    RedemptionAnalyticsResponse,
    ShopperListResponse,
    SignUpFormSettingsResponse,
} from '@shared/responseModels/business';
import {isObject, isString} from 'lodash';
import Notify, {NotificationKind} from '@/services/notifications/Notify';
import {useAppContext} from '@/AppContext';
import {BrandSettingsFormValues} from '@/pages/Settings/BrandSettings';
import {CustomerSettingsFormValues} from '@shared/requestSchemas/CustomerSettingsSchema';
import {AutoCampaignFormValues, RewardFormValues} from '@shared/requestSchemas';
import {FlaggedShopperListResponse} from '@shared/responseModels/business/FlaggedShopperListResponse';
import {useLoading} from '@/services/useLoading';

export function setClientTimeZoneHeader(timeZone: string) {
    axios.defaults.headers['client-time-zone'] = timeZone;
}

let isAxiosInterceptorsSetup = false;
export const axiosInterceptorsSetup = (navigate: (route: string) => void, notify: Notify) => {
    if (isAxiosInterceptorsSetup) {
        return;
    }
    isAxiosInterceptorsSetup = true;
    const { setLoading } = useLoading();

    axios.interceptors.request.use((config) => {
        setLoading(true);
        return config;
    });

    axios.interceptors.response.use(
        (response) => {
            setLoading(false);
            if (isObject(response.data)) {
                /* react-bootstrap throws a warning when unset values are null,
                 * Preemptively transform them to undefined instead.
                 */
                Object.keys(response.data).forEach((key: string) => {
                    if (response.data[key] === null) {
                        response.data[key] = undefined;
                    }
                });
            }

            if (response.config.method !== 'get' && !response?.data?.skipSuccessMessage) {
                const notificationKind = response?.data?.notificationKind ?? NotificationKind.Success;
                const notificationMessage = response?.data?.message ?? 'Success!';
                notify.show(notificationKind, notificationMessage);
            }
            return response;
        },
        error => {
            setLoading(false);
            if (error?.response?.status === 401) {
                window.location.href = '/login';
            }
            if (error?.response?.status === 403) {
                navigate('/');
            }
            if (error?.response?.status === 404) {
                navigate('/not-found');
            }

            const hasValidationErrors = error?.response?.data?.validationMessages?.length > 0;
            if (!hasValidationErrors && (error?.response?.status === 400 || error?.response?.status >= 500)) {
                const errorMessage = error?.response?.data?.errorMessage
                    || error?.response?.data?.message
                    || error?.response?.statusText
                    || error.message;
                notify.error(error, errorMessage);
            }
            return Promise.reject(error);
        },
    );
};

const imageRequestConfig = {
    headers: {
        'Content-Type': 'multipart/form-data',
    },
};

export function makeQueryString(params?: Record<string, string>) {
    return !params ?
        '' :
        Object.keys(params)
            .filter((key) => params[key] !== '')
            .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
            .join('&');
}

export const configurationResource = {
    get: () => axios.get('/api/configuration'),
};

// ------------------------------------------------------------------------------------------------
// Hooks
// ------------------------------------------------------------------------------------------------
export function useMeResource() {
    return useMemo(() => {
        return ({
            get: () => axios.get('/api/me'),
        });
    }, []);
}

export function useShopperResource() {
    const {selectedBusinessOption} = useAppContext();
    return useMemo(() => {
        const path = `/api/business/${selectedBusinessOption.value}/shoppers`;
        return ({
            getList: (params?: Record<string, string>): Promise<AxiosResponse<ShopperListResponse>> => {
                return axios.get(`${path}?${makeQueryString(params)}`);
            },
            getFlaggedList: (params?: Record<string, string>): Promise<AxiosResponse<FlaggedShopperListResponse>> => {
                return axios.get(`${path}/visit/flags?${makeQueryString(params)}`);
            },
            get: (membershipId: number | string): Promise<AxiosResponse<object>> => {
                return axios.get(`${path}/${membershipId}`);
            },
            create(values: object): Promise<AxiosResponse<object>> {
                return axios.post(`${path}`, values);
            },
            update(membershipId: number | string, values: object): Promise<AxiosResponse<object>> {
                return axios.put(`${path}/${membershipId}`, values);
            },
            setVip(membershipId: number | string, isVip: boolean) {
                return axios.put(`${path}/${membershipId}/vip`, {isVip});
            },
            setBlocked(membershipId: number | string, isBlocked: boolean) {
                return axios.put(`${path}/${membershipId}/blocked`, {isBlocked});
            },
            setPoints(membershipId: number | string, points: number) {
                return axios.put(`${path}/${membershipId}/points`, {points});
            },
            sendTextOptInMessage(membershipId: number) {
                return axios.post(`${path}/${membershipId}/send-text-opt-in-message`);
            },
        });
    }, [selectedBusinessOption]);
}

export function useCustomCampaignResource() {
    const {selectedBusinessOption} = useAppContext();
    const templateResource = useTemplateResource();
    const locationResource = useLocationResource();
    const businessId = selectedBusinessOption.value;
    return useMemo(() => {
        const path = `/api/business/${businessId}/custom-campaigns`;
        return ({
            getList: (params?: Record<string, string>): Promise<AxiosResponse<CustomCampaignListResponse>> => {
                return axios.get(`${path}?${makeQueryString(params)}`);
            },
            get: (campaignId: number): Promise<AxiosResponse<object>> => {
                return axios.get(`${path}/${campaignId}`);
            },
            async getCampaignPageConfig(campaignId?: number | null) {
                const requests = [
                    templateResource.getVariables(),
                    templateResource.getEmailTemplate(),
                    locationResource.getList(),
                ];
                if (campaignId) {
                    requests.push(this.get(campaignId));
                }
                const responses = await axios.all(requests);
                return {
                    variables: responses[0].data,
                    ...responses[1].data,
                    locations: responses[2].data,
                    campaign: responses[3]?.data,
                };
            },
            create(values: object): Promise<AxiosResponse<object>> {
                return axios.post(`${path}`, values);
            },
            update(campaignId: number | string, values: object): Promise<AxiosResponse<object>> {
                return axios.put(`${path}/${campaignId}`, values);
            },
            delete(campaignId: number | string) {
                return axios.delete(`${path}/${campaignId}`);
            },
            clone(campaignId: number | string) {
                return axios.post(`${path}/clone`, {campaignId});
            },
        });
    }, [selectedBusinessOption]);
}

export function useAutoCampaignResource() {
    const {selectedBusinessOption} = useAppContext();
    return useMemo(() => {
        const path = `/api/business/${selectedBusinessOption.value}/auto-campaigns`;
        return ({
            getList: (params?: Record<string, string>): Promise<AxiosResponse<object>> => {
                return axios.get(`${path}?${makeQueryString(params)}`);
            },
            get: (campaignId: number | string): Promise<AxiosResponse<AutoCampaignFormValues>> => {
                return axios.get(`${path}/${campaignId}`);
            },
            create(values: object): Promise<AxiosResponse<AutoCampaignFormValues>> {
                return axios.post(`${path}`, values);
            },
            getMilestonePrototype(campaignType: number): Promise<AxiosResponse<object>> {
                return axios.get(`${path}/milestone-prototype?${makeQueryString({campaignType: campaignType.toString()})}`);
            },
            update(campaignId: number | string, values: object): Promise<AxiosResponse<AutoCampaignFormValues>> {
                return axios.put(`${path}/${campaignId}`, values);
            },
            delete(campaignId: number | string): Promise<AxiosResponse<object>> {
                return axios.delete(`${path}/${campaignId}`);
            },
            setEnabled(campaignId: number | string, isEnabled: boolean) {
                return axios.put(`${path}/${campaignId}/enabled`, {isEnabled});
            },
        });
    }, [selectedBusinessOption]);
}

export function useLocationResource() {
    const {selectedBusinessOption} = useAppContext();
    return useMemo(() => {
        const path = `/api/business/${selectedBusinessOption.value}/locations`;
        return ({
            getList: (params?: Record<string, string>): Promise<AxiosResponse<LocationListResponse>> => {
                return axios.get(`${path}?${makeQueryString(params)}`);
            },
            getSelectOptions: () => {
                return axios.get(`${path}/select-options`);
            },
            get: (locationId: number | string): Promise<AxiosResponse<LocationDetailsResponse>> => {
                return axios.get(`${path}/${locationId}`);
            },
            create(values: object): Promise<AxiosResponse<LocationDetailsResponse>> {
                return axios.post(`${path}`, values);
            },
            update(locationId: number | string, values: object): Promise<AxiosResponse<LocationDetailsResponse>> {
                return axios.put(`${path}/${locationId}`, values);
            },
        });
    }, [selectedBusinessOption]);
}

export function useSignUpFormSettingsResource() {
    const {selectedBusinessOption} = useAppContext();
    return useMemo(() => {
        const path = `/api/business/${selectedBusinessOption.value}/locations`;
        return ({
            get: (locationId: number): Promise<AxiosResponse<SignUpFormSettingsResponse>> => {
                return axios.get(`${path}/${locationId}/sign-up-form-settings`);
            },
            update(locationId: number, values: object): Promise<AxiosResponse<SignUpFormSettingsResponse>> {
                return axios.put(`${path}/${locationId}/sign-up-form-settings`, values);
            },
        });
    }, [selectedBusinessOption]);
}

export function useSmsMessageContentResource() {
    return useMemo(() => {
        const path = `/api/sms-message-content`;
        return ({
            validateSmsMessage(message: string) {
                return axios.get(`${path}?${makeQueryString({message})}`);
            },
        });
    }, []);
}

export function useTemplateResource() {
    const {selectedBusinessOption} = useAppContext();
    return useMemo(() => {
        const path = `/api/business/${selectedBusinessOption.value}/templates`;
        return ({
            getVariables: () => {
                return axios.get(`${path}/variables`);
            },
            getEmailTemplate: () => {
                return axios.get(`${path}/email`);
            },
            sendPreviewEmail(values: object): Promise<AxiosResponse<object>> {
                return axios.post(`${path}/send-email-preview`, values);
            },
            sendPreviewCustomTemplateEmail(values: object): Promise<AxiosResponse<object>> {
                return axios.post(`${path}/send-template-email-preview`, values);
            },
        });
    }, [selectedBusinessOption]);
}

export function useRewardResource() {
    const {selectedBusinessOption} = useAppContext();
    return useMemo(() => {
        const path = `/api/business/${selectedBusinessOption.value}/rewards`;
        return ({
            getList: () => {
                return axios.get(`${path}`);
            },
            update(reward: RewardFormValues): Promise<AxiosResponse<object>> {
                if (reward.isPunchCardLoyaltyProgram) {
                    reward.maxPoints = isString(reward.maxPoints) ? parseInt(reward.maxPoints) : reward.maxPoints;
                }
                const rewardResource = reward.isPunchCardLoyaltyProgram ? 'single' : 'tiered';

                const requests = [
                    axios.put(`${path}/${rewardResource}`, reward),
                ];

                // const imageRequests = [];
                const isSingleRewardWithImage = !!(reward.isPunchCardLoyaltyProgram && reward.rewardImageFile);
                if (isSingleRewardWithImage) {
                    const formData = new FormData();
                    formData.append('rewardImageFile', reward.rewardImageFile!, reward.rewardImageFile?.name);
                    requests.push(
                        axios.post(`${path}/${rewardResource}-image`, formData, imageRequestConfig),
                    );
                } else if (!reward.isPunchCardLoyaltyProgram) {
                    for (let i = 0; i < reward.tieredRewards.length; i++) {
                        const tieredReward = reward.tieredRewards[i];
                        if (tieredReward.rewardImageFile) {
                            const formData = new FormData();
                            formData.append('rewardImageFile', tieredReward.rewardImageFile, tieredReward.rewardImageFile.name);
                            requests.push(
                                axios.post(
                                    `${path}/${i}/${rewardResource}-image`,
                                    formData,
                                    imageRequestConfig,
                                ),
                            );
                        }
                    }
                }

                return axios.all(requests).then(function(responses) {
                    const rewardResponse = responses.shift()?.data;
                    if (isSingleRewardWithImage) {
                        const rewardImageResponse = responses.shift()?.data ?? {};
                        return {
                            rewardItem: rewardResponse.rewardItem,
                            maxPoints: rewardResponse.maxPoints,
                            ...rewardImageResponse,
                            rewardImageFile: null,
                        };
                    }

                    for (let i = 0; i < responses.length; i++) {
                        const imageResponse = responses[i].data;
                        const responseIndex = rewardResponse.tieredRewards.findIndex((x: {
                            id: number
                        }) => x.id === imageResponse.id);
                        if (responseIndex > -1) {
                            rewardResponse.tieredRewards[responseIndex] = {
                                ...rewardResponse.tieredRewards[responseIndex],
                                ...imageResponse,
                            };
                        }
                    }

                    return {
                        ...rewardResponse,
                        rewardImageFile: null,
                    };
                });
            },
        });
    }, [selectedBusinessOption]);
}

export function useKioskSettingsResource() {
    const {selectedBusinessOption} = useAppContext();
    return useMemo(() => {
        const path = `/api/business/${selectedBusinessOption.value}/kiosk`;
        return ({
            getList: (params?: Record<string, string>): Promise<AxiosResponse<object>> => {
                return axios.get(`${path}?${makeQueryString(params)}`);
            },
            update(values: object): Promise<AxiosResponse<object>> {
                return axios.put(path, values);
            },
        });
    }, [selectedBusinessOption]);
}

export function useCampaignImagesResource() {
    const {selectedBusinessOption} = useAppContext();
    return useMemo(() => {
        const path = `/api/business/${selectedBusinessOption.value}/campaign-images`;
        return ({
            create(values: object): Promise<AxiosResponse<object>> {
                return axios.post(`${path}`, values, imageRequestConfig);
            },
        });
    }, [selectedBusinessOption]);
}

export function useBusinessSettingsResource() {
    const {selectedBusinessOption} = useAppContext();
    return useMemo(() => {
        const path = `/api/business/${selectedBusinessOption.value}/settings`;
        return ({
            getBusinessSettings: (params?: Record<string, string>): Promise<AxiosResponse<object>> => {
                return axios.get(`${path}/info?${makeQueryString(params)}`);
            },
            updateBusinessInfo(values: object): Promise<AxiosResponse<object>> {
                return axios.put(`${path}/info`, values);
            },
            updatePaymentMethod(stripePaymentMethodId: number | string) {
                return axios.put(`${path}/payment-method`, {stripePaymentMethodId});
            },
            getCustomerSettings: () => {
                return axios.get(`${path}/customer`);
            },
            updateCustomerSettings(customerSettings: CustomerSettingsFormValues) {
                return axios.put(`${path}/customer`, customerSettings);
            },
            getBrandSettings: () => {
                return axios.get(`${path}/brand`);
            },
            updateBrandSettings(brandSettings: BrandSettingsFormValues): Promise<BrandSettingsResponse> {
                const requests = [
                    axios.put(`${path}/brand`, brandSettings),
                ];

                if (brandSettings.logoFile) {
                    const formData = new FormData();
                    formData.append('logoFile', brandSettings.logoFile, brandSettings.logoFile.name);
                    requests.push(
                        axios.post(`${path}/brand-logo`, formData, imageRequestConfig),
                    );
                }
                if (brandSettings.punchCardImageFile) {
                    const formData = new FormData();
                    formData.append('punchCardImageFile', brandSettings.punchCardImageFile, brandSettings.punchCardImageFile.name);
                    requests.push(
                        axios.post(`${path}/punch-card-image`, formData, imageRequestConfig),
                    );
                }

                return axios
                    .all(requests)
                    .then(function(responses): BrandSettingsResponse {
                        const brandSettingsResponse = responses.shift()?.data;
                        const imageResponses = [];
                        for (let i = 0; i < responses.length; i++) {
                            imageResponses.push(responses[i].data);
                        }
                        const combinedImageResponse = imageResponses.length > 0 ? Object.assign([...imageResponses]) : {};
                        return {
                            ...brandSettingsResponse,
                            ...combinedImageResponse,
                            logoFile: null,
                        };
                    });
            },
        });
    }, [selectedBusinessOption]);
}

export function useBusinessAnalyticsResource() {
    const {selectedBusinessOption} = useAppContext();
    return useMemo(() => {
        const path = `/api/business/${selectedBusinessOption.value}/analytics`;
        return ({
            getAnalyticsCheckins: (params?: Record<string, string>): Promise<AxiosResponse<object>> => {
                return axios.get(`${path}/checkins?${makeQueryString(params)}`);
            },
            getAnalyticsCustomers: (params?: Record<string, string>): Promise<AxiosResponse<object>> => {
                return axios.get(`${path}/customers?${makeQueryString(params)}`);
            },
            getAnalyticsTouchPoints: (params?: Record<string, string>): Promise<AxiosResponse<object>> => {
                return axios.get(`${path}/touch-points?${makeQueryString(params)}`);
            },
            getAnalyticsRedemptions: (params?: Record<string, string>): Promise<AxiosResponse<RedemptionAnalyticsResponse>> => {
                return axios.get(`${path}/redemptions?${makeQueryString(params)}`);
            },
            getInsights: (params?: Record<string, string>): Promise<AxiosResponse<object>> => {
                return axios.get(`${path}/insights?${makeQueryString(params)}`);
            },
        });
    }, [selectedBusinessOption]);
}


import React from 'react';
import {useLoading} from '@/services';

export function GlobalOverlay() {
    const {loading} = useLoading();

    return (
        loading && (
            <div
                style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100vw',
                    height: '100vh',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    zIndex: 9999,
                }}
            />
        )
    );
}
